@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap'); */

body {
  font-family: "DM Sans", sans-serif !important;
  /* font-family: 'Roboto', sans-serif; */
}

option {
  color: black;
}
